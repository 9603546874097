let ch = {
    _isCommentFormAjaxRequest: false,
    _curCommand: '',
    _curCommandI: '',
    _commentNum: 0,

    Command_ArticleStat: 'arstat',
    Command_ArticleCommentsStat: 'arcommstat',
    Command_ShowSideVote: 'shvoteside',
    Command_ProcessVote: 'prvote',

    Initialize: function () {
        $(window).bind("resize", ch.OnWindowResize);
        $("body").click(function (event) {
            return ch.OnCommonClick(event);
        });
        ch.InitCommentForm();
    },

    OnCommonClick: function (event) {
        let clickedLink = $(event.target).closest("a");
        if (clickedLink.length > 0) {
            return ch.ProcessCommonLinkClick(clickedLink.get(0));
        }
        return true;
    },

    ProcessCommonLinkClick: function (target) {
        let href = $(target).attr("href");
        let hashText, hashCommand, hashCommandArg;
        if (href.indexOf('#') > -1) {
            hashText = href.substr(href.indexOf('#') + 1);
            if (hashText) {
                if (hashText.indexOf('-') > -1) {
                    hashCommand = hashText.substr(0, hashText.indexOf('-'));
                    hashCommandArg = hashText.substr(hashText.indexOf('-') + 1);
                } else {
                    hashCommand = hashText;
                }
            }
        }
        if (hashCommand) {
            switch (hashCommand) {
                case'replycomment':
                    ch.ToggleCommentForm(hashCommandArg);
                    return false;
                case'arcommvotepos':
                case'arcommvoteneg':
                    ch.ProcessCommentVoteClick(hashCommand, hashCommandArg);
                    return false;
            }
        }
        return true;
    },

    ProcessCommentVoteClick: function (command, commentNum) {
        if (ch._isCommentFormAjaxRequest)return;
        ch.ShowCommentFormProgressControl();
        ch._isCommentFormAjaxRequest = true;
        ch._curCommand = command;
        ch._commentNum = commentNum;

        let cookie = Cookies.get('cli2')

        if (!cookie) {
            ch.MakeClientIRequest();
        } else {
            ch.MakeCommentVoteRequest(command, commentNum);
        }
        return false;
    },

    MakeCommentVoteRequest: function (command, commentNum) {
        ch.ShowCommentFormProgressControl();
        ch._isCommentFormAjaxRequest = true;
        $.ajax({
            type: 'POST',
            url: '/commentgate.php',
            success: ch.ProcessCommentVoteServerResponse,
            data: {'c': command, 'i': commentNum, 'c2': command},
            error: ch.AjaxErr
        });
        return false;
    },

    ProcessCommentVoteServerResponse: function (responseText) {
        ch._isCommentFormAjaxRequest = false;
        ch.HideCommentFormProgressControl();
        let resParts = responseText.split('<|>');
        if (responseText.charAt(0) == '0') {
            let commentId = resParts[1],
                updatedComment = resParts[2];
            if (commentId && updatedComment) {
                $('#commentContainer-' + commentId).find('.commentVoting').replaceWith(updatedComment);
            }
            ch.ShowCommentVoteSuccessMsg(commentId);
        } else {
            ch.ShowCommentVoteErrorMsg(ch._commentNum, resParts[1]);
        }
    },

    ShowCommentVoteErrorMsg: function (commentId, errorText) {
        let errBlock = $('#commentVoteErrorMsgPlaceholder').text(errorText);
        ch.ShowCommentVoteMsgBlock(errBlock, commentId);
    },

    ShowCommentVoteMsgBlock: function (msgBlock, commentId) {
        let commentInfo = $('#commentContainer-' + commentId + ' .comment__info'),
            auxWidth = 0;
        msgBlock
            .clearQueue()
            .stop()
            .css({'opacity': 1})
            .css("display", "")
            .css("left", (commentInfo.position().left + commentInfo.outerWidth() - msgBlock.outerWidth()) - auxWidth)
            .css("top", commentInfo.position().top + commentInfo.outerHeight())
            .delay(2000)
            .fadeOut(1000);
    },

    ShowCommentVoteSuccessMsg: function (commentId) {
        let msgBlock = $('#commentVoteSuccessMsgPlaceholder');
        ch.ShowCommentVoteMsgBlock(msgBlock, commentId);
    },

    MakeClientIRequest: function () {
        $.ajax({
            type: 'POST',
            url: '/commentgate.php',
            success: ch.ProcessClientIServerResponse,
            data: {'c': 'ascli', 'i': 0, 'c2': 'ascli'},
            error: ch.AjaxErr
        });
        return false;
    },

    ProcessClientIServerResponse: function () {
        if (ch._curCommand) {
            if (ch._curCommand != ch.Command_ProcessVote && ch._curCommand != ch.Command_ArticleStat) {
                ch.MakeCommentVoteRequest(ch._curCommand, ch._commentNum);
            }
        }
    },

    ToggleCommentForm: function (cmi) {
        if (!ch.IsReplyCommentForm() || (ch.IsReplyCommentForm() && ch.GetCommentFormReplyI() != cmi)) {
            ch.ShowReplyCommentForm(cmi);
        } else {
            ch.ShowNormalCommentForm();
        }
    },

    ShowReplyCommentForm: function (cmi) {
        $('#comment_cmi').val(cmi);
        let commentform = $('#commentformcontainer').detach();
        $('#commentContainer-' + cmi).after(commentform);
        $('#commentFormClose').css("display", "");
    },

    InitCommentForm: function () {
        $("#commentFormSubmit").click(function (event) {
            ch.ProcessCommentSubmit(event);
            return false;
        });
        $("#commentFormClose").click(function () {
            ch.ShowNormalCommentForm();
            return false;
        });
    },

    ShowNormalCommentForm: function () {
        $('#comment_cmi').val('0');
        let commentform = $('#commentformcontainer').detach();
        $('#endcomments').after(commentform);
        $('#commentFormClose').css("display", "none");
    },

    ProcessCommentSubmit: function () {
        if (ch.ValidateCommentFormData()) {
            ch.SendWriteCommentRequestToServer();
        }
        return false;
    },

    SendWriteCommentRequestToServer: function () {
        if (ch._isCommentFormAjaxRequest) {
            return;
        }

        ch.ShowCommentFormProgressControl();
        ch._isCommentFormAjaxRequest = true;

        let authorV = $('#in_author').val(),
            emailV = $('#in_email').val(),
            commentV = $('#in_comment').val(),
            arid = $('#comment_ari').val(),
            cmid = $('#comment_cmi').val();

        $.ajax({
            type: 'POST',
            url: '/commentgate.php',
            success: ch.ProcessCommentServerResponse,
            data: {
                'c': 'arwritecomsend',
                'i': arid,
                'c2': 'arwritecomsend',
                'i2': cmid,
                'in_author': authorV,
                'in_email': emailV,
                'in_comment': commentV
            },
            error: ch.AjaxErr
        });
    },

    ProcessCommentServerResponse: function (responseText) {
        ch._isCommentFormAjaxRequest = false;
        ch.HideCommentFormProgressControl();
        if (responseText != '0') {
            alert('Ошибка добавления комментария');
            return;
        }
        ch.ShowFutureCommentPlaceholder();
        if (ch.IsReplyCommentForm()) {
            ch.RemoveCommentReplyLink(ch.GetCommentFormReplyI());
        }
        ch.ResetCommentForm();
        ch.ShowNormalCommentForm();
        alert('Комментарий добавлен');
    },

    ShowFutureCommentPlaceholder: function () {
        if (ch.IsReplyCommentForm()) {
            let cmi = ch.GetCommentFormReplyI(),
                placeholder = $('#addedCommentPlaceholder').clone().css("display", "");
            $('#commentContainer-' + cmi).after(placeholder);
        } else {
            let placeholder = $('#addedCommentPlaceholder').clone().css("display", "");
            $('#endcomments').before(placeholder);
        }
    },

    ResetCommentForm: function () {
        $('#in_author').val('');
        $('#in_email').val('');
        $('#in_comment').val('');
        $('#comment_cmi').val('0');
    },

    RemoveCommentReplyLink: function (cmi) {
        $('#commentContainer-' + cmi + ' .replyLink').remove();
    },

    GetCommentFormReplyI: function () {
        return $('#comment_cmi').val();
    },

    IsReplyCommentForm: function () {
        return $('#comment_cmi').val() != '0';
    },

    ValidateCommentFormData: function () {
        let author = $('#in_author'),
            author_val = author.val(),
            //email = $('#in_email'),
            //email_val = email.val(),
            comment = $('#in_comment'),
            comment_val = comment.val();

        if (!author_val) {
            alert("Поле 'Имя' пустое");
            author.focus();
            return false;
        }
        if (!comment_val) {
            alert("Поле 'Комментарий' пустое");
            comment.focus();
            return false;
        }
        return true;
    },

    AjaxErr: function () {
        ch._isCommentFormAjaxRequest = false;
        alert('Ошибка связи с сервером');
        ch.HideCommentFormProgressControl();
    },

    OnWindowResize: function () {
        if (ch._isCommentFormAjaxRequest) {
            ch.HideCommentFormProgressControl();
            ch.ShowCommentFormProgressControl();
        }

    },

    HideCommentFormProgressControl: function () {
        $("#progressimage").css("display", "none");
        $("#commentFormProgressPanel").css("display", "none");
    },

    ShowCommentFormProgressControl: function () {
        try {
            let progressPanel = $("#commentFormProgressPanel"),
                progressImage = $("#progressimage"),
                commentFormCtrl = $("#commentformcontainer"),
                in_comment = $("#in_comment"),
                progressImageLeft = in_comment.position().left + (in_comment.outerWidth() / 2);

            progressPanel
                .css("display", "block")
                .css("left", 0)
                .css("top", 0)
                .css("width", $(document).width())
                .css("height", $(document).height())
                .css({'opacity': 0.8});

            progressImage
                .css("display", "block")
                .css("left", progressImageLeft)
                .css("top", commentFormCtrl.position().top + commentFormCtrl.outerHeight() / 2);
        } catch (ex) {
        }
    },
};
