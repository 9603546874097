(function () {
    'use strict';

    ch.Initialize();

    let body        = $('body'),
        wrapper     = $('.main-wrap'),
        sidebar     = $('.l-sidebar__inner'),
        categories  = $('#header_categories'),
        cat_button  = categories.find('div.button'),
        search      = $('.search'),
        searchShow  = $('.js-search-show'),
        searchHide  = $('.js-search-hide'),
        tabs = $('.js-tabs'),
        tab = tabs.find('.js-tab').children(),
        tabContent = tabs.find('.js-tabcontent').children(),
        activeClass = 'is-active';

    tabContent.not(':first-child').hide();
    tab.click(function (event) {
        var activeTabIndex = $(this).index();
        tab.removeClass(activeClass);
        $(this).addClass(activeClass);
        tabContent.hide();
        tabContent.eq(activeTabIndex).show();
        return false;
    });

    searchShow.click(function (event) {
        search.slideToggle();
        searchShow.toggleClass('is-inactive');
        return false;
    });
    searchHide.click(function (event) {
        search.slideUp();
        searchShow.removeClass('is-inactive');
        return false;
    });

    cat_button.on('click', function (e) {
        let cur_height	= categories.height(),
            auto_height	= categories.css('height', 'auto').height(),
            cat_speed	= 300,
            cat_height	= 34;

        if (cur_height > cat_height) {
            categories.animate({height: cat_height}, cat_speed);
        } else {
            categories.height(cur_height).animate({height: auto_height}, cat_speed);
        }

        cat_button.toggleClass('icon-down-open icon-up-open');
    });

    $('.js-add-file').on('click', function () {
        let n = $('.wrapper-input-file .comments__input:last').data('file') + 1;
        if (n <= 10)
            $('.wrapper-input-file')
                .append('<div class="comments__input" data-file="' + n + '"><input type="file" name="in_file_' + n + '" id="in_file_' + n + '"></div>');
        return false;
    })

    $(document).scroll(function (event) {
        if ($(document).scrollTop() > 400) {
            body.addClass('can-gototop')
        } else {
            body.removeClass('can-gototop')
        }

        if ($(window).width() < 1150) {
            return;
        }

        let sidebarBottomPosition = wrapper.offset().top + sidebar.height(),
            wrapperBottomPosition = wrapper.offset().top + wrapper.height();

        if (($('.l-main').height() - 15) > sidebar.height()) {
            let targetTopPosition = $(document).scrollTop() + $(window).height();

            if (targetTopPosition >= sidebarBottomPosition) {
                sidebar.css({position: 'fixed', top: 'auto', bottom: '0'});
            } else {
                sidebar.css({position: 'static', top: 'auto', bottom: 'auto'});
            }

            if (targetTopPosition >= wrapperBottomPosition) {
                sidebar.css({position: 'absolute', top: 'auto', bottom: '0'});
            }
        }
    }).ready( function () {
        let obj = $("article.article");
        if(obj.length){
            let article_id = obj.data('article_id');
            if (article_id) {
                $.post("https://hit.c97.org/counter/" + article_id, function (data) {
                    console.log('counter:'+data)
                });
            }
        }
    });

    $('.js-gototop').click(function (event) {
        // event.preventDefault();
        $('html, body').animate({scrollTop: 0}, 500);
    });

    $('.wrapper-sponsors').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        autoplay: true,
        draggable: false,
        responsive: [
            {
                breakpoint: 979,
                settings: { slidesToShow: 4 }
            },
            {
                breakpoint: 768,
                settings: { slidesToShow: 3 }
            }
        ]
    });
})();
